import type {TableData} from '@Libraries/add-media-library';
import type {CellObject} from '@PosterWhiteboard/items/layouts/cells/cell';
import {Cell, CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {ScheduleData} from '@Panels/user-schedule-panel/user-schedule-panel.types';
import {getColumnsForLayout} from '@PosterWhiteboard/items/layouts/layout.library';
import type {CellData, DateData, TableDataType, TimeData} from '@Components/table/table.types';
import {DateFormat, TimeFormat} from '@Components/table/table.types';
import {getCellDataForCellType} from '@Components/table/table-helper';
import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {LayoutDataMap} from '@PosterWhiteboard/items/table-item/table-item.types';

export const DEFAULT_ROWS = 3;
export const DEFAULT_COLUMNS = 3;
export const SPORTS_LAYOUT_Y_SPACING = 5;
export const OFFSET_LAYOUT_Y_SPACING = 40;

interface RawScheduleData {
  unusedData: Array<Array<TableDataType>>;
  highlightedRows: Array<number>;
  tableData: Array<Array<TableDataType>>;
  columnData: Array<CellType>;
  dateFormat: DateFormat;
  columns: number;
  timeFormat: TimeFormat;
}

interface TableModalData {
  highlightedRows: Array<number>;
  tableData: Array<Array<string>>;
}

interface UnusedScheduleData {
  unusedData: Array<Array<TableDataType>>;
  timeFormat: TimeFormat;
}

export const getDefaultTableData = (): string[][] => {
  const tableData: string[][] = [];

  for (let rowIndex = 0; rowIndex < DEFAULT_ROWS; rowIndex++) {
    const tableRow: string[] = [];

    for (let colIndex = 0; colIndex < DEFAULT_COLUMNS; colIndex++) {
      tableRow.push(window.i18next.t('pmwjs_add_text'));
    }

    tableData.push(tableRow);
  }

  return tableData;
};

export const getTableData = (layoutData: LayoutDataMap, rows: number): TableModalData => {
  const tableData = [];
  const highlightedRows: Array<number> = [];
  for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
    const rowData: Array<string> = [];

    for (const [cellType, cells] of Object.entries(layoutData) as [CellType, Cell[]][]) {
      if (cellType !== CellType.HIGHLIGHT) {
        const text = cells[rowIndex].value ? (cells[rowIndex].value as string) : '';
        rowData.push(text);
      } else if (cells[rowIndex].value) {
        highlightedRows.push(rowIndex);
      }
    }
    tableData.push(rowData);
  }
  return {
    tableData,
    highlightedRows,
  };
};

export const getDefaultScheduleData = (): RawScheduleData => {
  const tableData = [];
  const columnData: Array<CellType> = [];
  const DATE_FORMAT = DateFormat.FORMAT_6;
  const columns = getColumnsForLayout(LayoutTypes.SLANTED_SPORTS_LAYOUT);

  for (let rowIndex = 0; rowIndex < DEFAULT_ROWS; rowIndex++) {
    const tableRow: Array<string | CellData | TimeData | DateData> = [];
    const columnIds = Object.keys(columns) as Array<CellType>;

    columnIds.forEach((columnId) => {
      if (columnId !== CellType.HIGHLIGHT) {
        tableRow.push(getCellDataForCellType(columnId, TimeFormat.STANDARD, DATE_FORMAT));
        if (!columnData.includes(columnId)) {
          columnData.push(columnId);
        }
      }
    });
    tableData.push(tableRow);
  }
  return {
    unusedData: [],
    highlightedRows: [],
    tableData,
    columnData,
    dateFormat: DATE_FORMAT,
    columns: columnData.length,
    timeFormat: TimeFormat.STANDARD,
  };
};

export const getScheduleData = (model: TableItem): RawScheduleData => {
  const layoutData = model.layoutDataMap;
  const unusedLayoutData = getUnusedDataForSchedule(model);
  const tableData = [];
  const columnData: Array<CellType> = [];
  const highlightedRows: Array<number> = [];
  let timeFormat;
  let dateFormat = DateFormat.FORMAT_1;

  for (let r = 0; r < model.rows; r++) {
    const rowData: Array<DateData | TimeData | CellData> = [];

    for (const [cellType, cells] of Object.entries(layoutData) as [CellType, Cell[]][]) {
      if (cellType === CellType.DATE) {
        let cellData = cells[r].value as DateData;
        cellData = {
          ...cellData,
          type: cellType,
        };
        rowData.push(cellData);
        if (!columnData.includes(cellType)) {
          columnData.push(cellType);
        }
        dateFormat = cellData.dateFormat;
      } else if (cellType === CellType.TIME) {
        let cellData = cells[r].value as TimeData;
        let cellDataTimeFormat: TimeFormat;
        if (cellData.timeFormat) {
          cellDataTimeFormat = cellData.timeFormat;
        } else if (cellData.meridiem.length) {
          cellDataTimeFormat = TimeFormat.STANDARD;
        } else {
          cellDataTimeFormat = TimeFormat.MILITARY;
        }
        cellData = {
          ...cellData,
          type: cellType,
          timeFormat: cellDataTimeFormat,
        };
        timeFormat = cellData.timeFormat;
        rowData.push(cellData);
        if (!columnData.includes(cellType)) {
          columnData.push(cellType);
        }
      } else if (cellType !== CellType.HIGHLIGHT) {
        const text = cells[r].value ? cells[r].value : '';
        rowData.push({
          type: cellType,
          value: text,
        } as CellData);
        if (!columnData.includes(cellType)) {
          columnData.push(cellType);
        }
      } else if (cells[r].value) {
        highlightedRows.push(r);
      }
    }

    tableData.push(rowData);
  }
  return {
    tableData,
    columnData,
    dateFormat,
    columns: columnData.length,
    highlightedRows,
    unusedData: unusedLayoutData.unusedData,
    timeFormat: timeFormat || unusedLayoutData.timeFormat,
  };
};

export const getUnusedDataForSchedule = (model: TableItem): UnusedScheduleData => {
  const unusedLayoutData = model.unusedData;
  const unusedData = [];
  let timeFormat = TimeFormat.STANDARD;

  for (let r = 0; r < model.rows; r++) {
    const unusedDataRow: Array<DateData | TimeData | CellData> = [];

    for (const [cellType, cells] of Object.entries(unusedLayoutData) as [CellType, Cell[]][]) {
      if (!cells[r]) {
        unusedDataRow.push(getCellDataForCellType(cellType, timeFormat) as CellData | TimeData | DateData);
      } else if (cellType === CellType.TIME) {
        let cellData = cells[r].value as TimeData;
        let cellDataTimeFormat: TimeFormat;
        if (cellData.timeFormat) {
          cellDataTimeFormat = cellData.timeFormat;
        } else if (cellData.meridiem.length) {
          cellDataTimeFormat = TimeFormat.STANDARD;
        } else {
          cellDataTimeFormat = TimeFormat.MILITARY;
        }
        cellData = {
          ...cellData,
          type: cellType,
          timeFormat: cellDataTimeFormat,
        };
        timeFormat = cellData.timeFormat;
        unusedDataRow.push(cellData);
      } else {
        const text = cells[r].value ? cells[r].value : '';
        unusedDataRow.push({
          type: cellType,
          value: text,
        } as CellData);
      }
    }

    unusedData.push(unusedDataRow);
  }
  return {
    unusedData,
    timeFormat,
  };
};

export const invalidateTableDataForCustomTableLayout = (data: TableData): Record<string, Array<CellObject>> => {
  const {tableData} = data;
  const invalidatedData: Record<string, Array<CellObject>> = {};
  for (let i = 0; i < data.rows; i++) {
    for (let j = 0; j < data.columns; j++) {
      const val = tableData[i][j];
      const prop = `column${j}`;

      if (typeof invalidatedData[prop] === 'undefined') {
        invalidatedData[prop] = [];
      }
      // @ts-expect-error the prop isnot in celltype if column is above 3. The TS needs to improved for this
      invalidatedData[prop].push(new Cell(prop, val).toObject());
    }
    if (invalidatedData[CellType.HIGHLIGHT] === undefined) {
      invalidatedData[CellType.HIGHLIGHT] = [];
    }
    invalidatedData[CellType.HIGHLIGHT].push(new Cell(CellType.HIGHLIGHT, data.highlightedRows.includes(i)).toObject());
  }
  return invalidatedData;
};

export const invalidateTableDataForFixedLayout = (data: ScheduleData): Record<string, Array<CellObject>> => {
  const {tableData} = data;
  const invalidatedData: Record<string, Array<CellObject>> = {};
  for (let i = 0; i < data.rows; i++) {
    for (let j = 0; j < data.columns; j++) {
      const prop = data.columnsData[j];
      let val;
      if (prop === CellType.DATE || prop === CellType.TIME) {
        val = tableData[i][j] as TimeData | DateData;
      } else {
        const cellData = tableData[i][j] as CellData;
        val = cellData.value;
      }

      if (typeof invalidatedData[prop] === 'undefined') {
        invalidatedData[prop] = [];
      }
      invalidatedData[prop].push(new Cell(prop, val).toObject());
    }
    if (invalidatedData[CellType.HIGHLIGHT] === undefined) {
      invalidatedData[CellType.HIGHLIGHT] = [];
    }
    invalidatedData[CellType.HIGHLIGHT].push(new Cell(CellType.HIGHLIGHT, data.highlightedRows.includes(i)).toObject());
  }
  return invalidatedData;
};

export const invalidateUnusedData = (data: ScheduleData): Record<string, Array<CellObject>> => {
  const tableData = data.unusedData;
  const invalidatedData: Record<string, Array<CellObject>> = {};
  for (let i = 0; i < tableData.length; i++) {
    for (let j = 0; j < tableData[i].length; j++) {
      const prop = tableData[i][j].type;
      let val;
      if (prop === CellType.DATE || prop === CellType.TIME) {
        val = tableData[i][j] as TimeData | DateData;
      } else {
        const cellData = tableData[i][j] as CellData;
        val = cellData.value;
      }
      if (typeof invalidatedData[prop] === 'undefined') {
        invalidatedData[prop] = [];
      }
      invalidatedData[prop].push(new Cell(prop, val).toObject());
    }
  }
  return invalidatedData;
};
